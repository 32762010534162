<template>
  <div :class="{ modal: true, fade: fade }" tabindex="-1" role="dialog" :id="id">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><slot name="title"></slot></h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// /*global $*/
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    fade: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  unmounted() {
    // console.log('Modal unmounted()');
    // if ($('.modal').length()) {
    //   $('.modal').modal('hide');
    // }
  },
};
</script>

<style scoped lang="scss"></style>

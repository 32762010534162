import * as _ from 'lodash';

const formHelpers = {
  data() {
    return {
      select2Settings: {
        allowClear: true,
        theme: 'bootstrap4',
        width: '100%',
      },
    };
  },
  methods: {
    isNew() {
      return _.isEmpty(this.initial);
    },
    toFormData(data) {
      const formData = new URLSearchParams(); // FormData();

      if (this.allowedFields) {
        this.allowedFields.forEach((key) => {
          if (data[key]) {
            formData.append(key, data[key]);
          }
        });
      } else {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }

      return formData;
    },
    maybeParseJson(json) {
      // console.log('formHelpers.js methods maybeParseJson() :json', typeof json, json);

      if (typeof json === 'object') {
        return json;
      }

      return JSON.parse(json);
    },
  },
};

// leave the export, even if you don't use it
export default formHelpers;

<template>
  <div
    class="card campaign-card campaign-card-extended mb-4 w-100"
    :class="{ 'border-primary': isCurrent }"
  >
    <Error v-if="isError" :error="errorPromotions" />
    <Loading v-else-if="isLoading" class="my-4" />
    <div v-else class="card-body">
      <div class="row mb-5">
        <div class="col">
          <h3 class="card-title">
            <router-link :to="{ name: 'campaign', params: { id: campaign.campaign_id } }">
              {{ campaign.title }}
            </router-link>
          </h3>
          <p class="card-text text-muted">
            {{ campaign.description }}
          </p>
        </div>
        <div class="col">
          <FromTo :starts_at="campaign.starts_at" :ends_at="campaign.ends_at" class="mb-3" />
          <div
            class="countdown"
            :class="{ 'countdown-almost-complete': isAlmostEnding(campaign.ends_at) }"
          >
            <span v-if="tillNow(campaign.ends_at).months">
              <strong>{{ tillNow(campaign.ends_at).months }}</strong> months
            </span>
            <span v-if="tillNow(campaign.ends_at).weeks">
              <strong>{{ tillNow(campaign.ends_at).weeks }}</strong> weeks
            </span>
            <span v-if="tillNow(campaign.ends_at).days">
              <strong>{{ tillNow(campaign.ends_at).days }}</strong> days
            </span>
            <span v-if="tillNow(campaign.ends_at).hours">
              <strong>{{ tillNow(campaign.ends_at).hours }}</strong> hours
            </span>
            <span v-if="tillNow(campaign.ends_at).minutes">
              <strong>{{ tillNow(campaign.ends_at).minutes }}</strong> minutes
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <CampaignStats
            :campaign="campaign"
            :donations="donations"
            :pledges="pledges"
            class="mb-5"
          />
          <ProgressBarHorizontal
            :min="0"
            :max="100"
            height="2rem"
            :percentage="progressPercentage.value"
            :end-label="formatAmountStat(targets.all, '$ 0.0a')"
            :progress-label="progressPercentage.label"
          />
        </div>
        <div class="col-6">
          <h5>Currently Running Promotion</h5>
          <PromotionCard
            :promotion="currentPromotion"
            :show-campaign="false"
            v-if="currentPromotion"
          />
          <EmptyCard
            v-else
            title="No Promotion is currently running"
            icon="frown"
            class="text-primary"
          />

          <h6 class="text-muted">Upcoming Promotion</h6>
          <p v-if="nextPromotion">
            <router-link :to="{ name: 'promotion', params: { id: nextPromotion.promotion_id } }">
              {{ nextPromotion.title }}
            </router-link>
          </p>
          <p v-else>None Scheduled</p>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <router-link
        :to="{ name: 'campaign', params: { id: campaign.campaign_id } }"
        class="btn btn-primary btn-sm mr-2"
      >
        View
      </router-link>
      <button
        v-if="false && can('update', 'campaigns')"
        class="btn btn-secondary btn-sm mr-2"
        type="button"
        data-toggle="modal"
        data-target="#modal-campaign-edit"
        @click="$emit('setCampaign', campaign)"
      >
        Edit
      </button>
      <small class="text-muted">
        Updated:
        <span :title="fullDateTime(campaign.updated_at)">{{ timeAgo(campaign.updated_at) }}</span>
      </small>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import hasTimeDate from 'mixins/hasTimeDate';
import hasStatistics from 'mixins/hasStatistics';
import helpers from 'mixins/helpers';
import CampaignStats from 'components/stats/CampaignStats';
import EmptyCard from 'components/status-cards/EmptyCard';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FromTo from 'components/FromTo';
import ProgressBarHorizontal from 'components/stats/ProgressBarHorizontal';
import PromotionCard from 'components/promotions/PromotionCard';

dayjs.extend(isBetween);
export default {
  name: 'CampaignCardExtended',
  components: {
    CampaignStats,
    EmptyCard,
    Error,
    Loading,
    FromTo,
    ProgressBarHorizontal,
    PromotionCard,
  },
  mixins: [hasStatistics, hasTimeDate, helpers],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progressPercentage() {
      const percent = (this.statistics.total_sum / this.targets.all) * 100;

      return {
        value: percent,
        label: `${percent.toFixed(2).toString()}%`,
      };
    },
    targets() {
      if (this.campaign.meta && this.campaign.meta.targets) {
        return this.campaign.meta.targets;
      }

      return {
        all: 0,
        single: 0,
        recurring: 0,
      };
    },
    isCurrent() {
      // Is this Campaign currently running?
      return this.isBetween(this.campaign.starts_at, this.campaign.ends_at);
    },
    currentPromotion() {
      const current = this.promotions.filter((promotion) => {
        // start < now < end
        const start = this.verifyDayjs(promotion.starts_at);
        const end = this.verifyDayjs(promotion.ends_at);

        return dayjs().isBetween(start, end);
      });

      return current.length >= 1 ? current[0] : false;
    },
    nextPromotion() {
      const current = this.promotions.filter((promotion) => {
        // start > now
        const start = this.verifyDayjs(promotion.starts_at);
        const end = this.verifyDayjs(promotion.ends_at);

        return dayjs().isBefore(start) && dayjs().isBefore(end);
      });

      return current.length >= 1 ? current[0] : false;
    },
    statistics() {
      return this.campaign.statistics;
    },
    isLoading() {
      return this.isLoadingPromotions || this.isLoadingDonations || this.isLoadingPledges;
    },
    isError() {
      return this.errorPromotions || this.errorDonations || this.errorPledges;
    },
  },
  data() {
    return {
      promotions: [],
      donations: [],
      pledges: [],
      errorPromotions: null,
      errorDonations: null,
      errorPledges: null,
      isLoadingPromotions: false,
      isLoadingDonations: false,
      isLoadingPledges: false,
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.errorPromotions = null;
      this.errorDonations = null;
      this.errorPledges = null;
      this.isLoadingPromotions = true;
      this.isLoadingDonations = true;
      this.isLoadingPledges = true;

      const promotionsPayload = {
        id: this.campaign.campaign_id,
        params: {
          order_by: 'starts_at',
          order_dir: 'ASC',
          per_page: 2,
          timeslice: `starts_at;${this.formatISODate(dayjs())}|${this.formatISODate(
            dayjs().add('2', 'day')
          )}`,
          with: 'statistics;intentions',
        },
      };

      this.$store
        .dispatch('campaigns/getPromotions', promotionsPayload)
        .then((response) => {
          // console.log('CampaignCardExtended fetchData() then', promotionsPayload, response.data);
          this.promotions = response.data.data;
          this.isLoadingPromotions = false;
        })
        .catch((error) => {
          this.errorPromotions = this.$errorProcessor(error);
        });

      const { starts_at, ends_at } = this.campaign;
      const campaignDateRange = `${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`;

      const donationsPayload = {
        id: this.campaign.campaign_id,
        params: {
          per_page: 0,
          timeslice: `created_at;${campaignDateRange}`,
        },
      };

      this.$store
        .dispatch('campaigns/getDonations', donationsPayload)
        .then((response) => {
          // console.log('CampaignCardExtended fetchData() then', donationsPayload, response.data);
          this.donations = response.data.data;
          this.isLoadingDonations = false;
        })
        .catch((error) => {
          this.errorDonations = this.$errorProcessor(error);
        });

      const pledgesPayload = {
        id: this.campaign.campaign_id,
        params: {
          per_page: 0,
          timeslice: `includable_at;${campaignDateRange}`,
        },
      };

      this.$store
        .dispatch('pledges/getAll', pledgesPayload)
        .then((response) => {
          // console.log('CampaignCardExtended fetchData() then', pledgesPayload, response.data);
          this.pledges = response.data.data;
          this.isLoadingPledges = false;
        })
        .catch((error) => {
          this.errorPledges = this.$errorProcessor(error);
        });
    },
  },
};
</script>

<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Campaigns" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <CampaignsNavigation @searchQuery="searchQuery" />
      <div class="row" v-if="currentCampaign">
        <div class="col">
          <h2>{{ currentTitle }}</h2>
          <CampaignCardExtended :campaign="currentCampaign" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <PaginationResults
            title-suffix="campaigns"
            :total="meta.pagination.total"
            :current-page="pageNum"
            :total-pages="meta.pagination.total_pages"
            :per-page="perPage"
            @searchQuery="searchQuery"
          />
        </div>
        <div class="col-3 m-auto" v-if="!hasCampaigns">
          <EmptyCard title="There are no campaigns" />
        </div>
        <template v-else>
          <div
            class="col-sm-6 col-md-4 d-flex"
            v-for="campaign in campaigns"
            :key="`campaign-card-${campaign.campaign_id}`"
          >
            <CampaignCard :campaign="campaign" />
          </div>
        </template>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>

    <Modal id="modal-campaign-create">
      <template v-slot:title>Create Campaign</template>

      <template v-slot:body>
        <CampaignForm modal="#modal-campaign-create" @complete="fetchData" />
      </template>
    </Modal>

    <Modal v-if="false" id="modal-campaign-edit">
      <template v-slot:title>Edit Campaign</template>

      <template v-slot:body>
        <CampaignForm modal="#modal-campaign-edit" :initial="campaign" @complete="fetchData" />
      </template>
    </Modal>
  </div>
</template>

<script>
import hasTimeDate from 'mixins/hasTimeDate';
import paginationFunctions from 'mixins/paginationFunctions';
import CampaignCard from 'components/campaigns/CampaignCard';
import CampaignCardExtended from 'components/campaigns/CampaignCardExtended';
import CampaignForm from 'components/forms/CampaignForm';
import CampaignsNavigation from 'components/campaigns/CampaignsNavigation';
import EmptyCard from 'components/status-cards/EmptyCard';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';
import PaginationResults from 'components/PaginationResults';

export default {
  name: 'CampaignsIndex',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    CampaignCard,
    CampaignCardExtended,
    CampaignForm,
    CampaignsNavigation,
    EmptyCard,
    Error,
    Loading,
    Modal,
    PageHeader,
    Pagination,
    PaginationResults,
  },
  data() {
    return {
      // campaign: {},
      error: null,
      isLoading: true,
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
    };
  },
  computed: {
    campaigns() {
      return this.$store.getters['campaigns/getAll'] ?? [];
    },
    currentCampaign() {
      return this.currentCampaigns[0] || false;
    },
    currentCampaigns() {
      return this.$store.getters['campaigns/getCurrentCampaigns'] || false;
    },
    currentTitle() {
      return this.currentCampaign.length > 1
        ? 'Currently Running Campaigns'
        : 'Currently Running Campaign';
    },
    hasCampaigns() {
      return this.campaigns.length > 0;
    },
    meta() {
      return this.$store.getters['campaigns/getAllMeta'];
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    dates() {
      return this.$store.getters['search/getRange'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  unmounted() {
    // console.log('CampaignsIndex unmounted()');
    this.$store.commit('search/clearSearch');
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'statistics',
      };

      const allPayload = {
        params: Object.assign(defaultParams, params || {}),
      };

      if (this.q) {
        allPayload.params.search = this.q;
      }

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        allPayload.params.timeslice = dateLimits;
      }

      // console.log('CampaignsIndex methods fetchData() :allPayload', allPayload);

      const getAll = this.$store
        .dispatch('campaigns/getAll', allPayload)
        .then(() => {
          // console.log('CampaignsIndex methods fetchData() getAll then');
          // this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });

      const currentPayload = {
        params: {
          with: 'statistics',
        },
      };

      // console.log('CampaignsIndex methods fetchData() :currentPayload', currentPayload);

      const getCurrent = this.$store
        .dispatch('campaigns/getCurrent', currentPayload)
        .then(() => {
          // console.log('CampaignsIndex methods fetchData() getCurrent then');
          // this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });

      Promise.all([getAll, getCurrent]).then(() => {
        this.isLoading = false;
      });
    },
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let start = false;
      let end = false;

      if (this.dates) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        ({ start, end } = this.dates);
      }

      if (start && end) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(start)}|${this.formatISODate(end)}`;
      }

      return limits;
    },
    searchQuery() {
      // console.log('CampaignsIndex methods searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
    // setCampaign(campaign) {
    //   console.log('CampaignsIndex methods setCampaign() :campaign', campaign);
    //   this.campaign = campaign;
    // },
  },
};
</script>

<style scoped lang="scss">
.card-group {
  .card {
    flex-basis: 25%;
    flex-grow: 0;
  }
}
</style>

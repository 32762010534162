<template>
  <div class="card campaign-card mb-4 w-100" :class="{ 'border-primary': isCurrent }">
    <div v-if="error" class="card-body">
      <Error :error="error" />
    </div>
    <div v-else-if="isLoading" class="card-body">
      <Loading />
    </div>
    <template v-else>
      <div class="card-body card-body-extend">
        <h3 class="card-title">
          <router-link :to="{ name: 'campaign', params: { id: campaign.campaign_id } }">
            {{ campaign.title }}
          </router-link>
        </h3>
        <p class="card-text text-muted">
          {{ campaign.description }}
        </p>
      </div>

      <div class="card-body">
        <CampaignStats :campaign="campaign" :donations="donations" class="mb-5" />
        <FromTo :starts_at="campaign.starts_at" :ends_at="campaign.ends_at" />
      </div>
    </template>

    <div class="card-footer">
      <router-link
        :to="{ name: 'campaign', params: { id: campaign.campaign_id } }"
        class="btn btn-primary btn-sm mr-2"
      >
        View
      </router-link>
      <button
        v-if="false && can('update', 'campaigns')"
        class="btn btn-secondary btn-sm mr-2"
        type="button"
        data-toggle="modal"
        data-target="#modal-campaign-edit"
        @click="$emit('setCampaign', campaign)"
      >
        Edit
      </button>
      <small class="text-muted">
        Updated:
        <span :title="fullDateTime(campaign.updated_at)">{{ timeAgo(campaign.updated_at) }}</span>
      </small>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import CampaignStats from 'components/stats/CampaignStats';
import Error from 'components/Error';
import FromTo from 'components/FromTo';
import Loading from 'components/Loading';

export default {
  name: 'CampaignCard',
  mixins: [hasTimeDate, helpers],
  components: {
    CampaignStats,
    Error,
    FromTo,
    Loading,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCurrent() {
      // Is this Campaign currently running?
      return this.isBetween(this.campaign.starts_at, this.campaign.ends_at);
    },
  },
  data() {
    return {
      donations: [],
      donationsMeta: {},
      error: null,
      isLoading: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = null;
      this.isLoading = true;

      const payload = {
        id: this.campaign.campaign_id,
        params: {},
        saveToStore: true,
      };

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('CampaignCard fetchData() campaigns/getDonations then', payload, response.data);
          this.donations = response.data.data.filter((d) => {
            return (
              !this.hasProperty(d, 'deleted_at') ||
              (this.hasProperty(d, 'deleted_at') && !dayjs(d.deleted_at).isValid())
            );
          });
          this.donationsMeta = response.data.meta;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
  },
};
</script>

<template>
  <div :class="getClasses()" role="alert">
    <h6 class="alert-heading">
      <font-awesome-icon icon="exclamation-circle" /> {{ errorMessage }}
    </h6>
    <ul v-if="errorBag.length" class="mb-0">
      <li v-for="(error, i) in errorBag" :key="`error-bag-${i}`">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    error: {
      type: [Error, Boolean],
      required: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    errorString: {
      type: [String, Boolean],
      required: false,
    },
  },
  computed: {
    errorBag() {
      // console.log('FormError errorBag()', this.error);
      let errors = [];
      if (this.error.errorBag) {
        errors = Object.keys(this.error.errorBag).flatMap((key) => this.error.errorBag[key]);
      }

      // console.log('FormError errorBag() errors', errors);
      return errors;
    },
    errorMessage() {
      if (this.errorString) {
        return this.errorString;
      }

      if (typeof this.error !== 'object') {
        return 'Unknown Error';
      }

      if (this.response && this.response.data) {
        const { data } = this.response;
        // console.log('FormError errorMessage() response.data', data);
        let message = 'Unknown Error';
        if (data.message) {
          message = data.message;
        }
        if (data.error) {
          message = data.error;
        }

        return this.cleanMessage(message);
      }

      // console.log('FormError errorMessage() this.error.message', this.error.message);
      return this.error.message ? this.cleanMessage(this.error.message) : 'Unknown Error';
    },
    errorType() {
      return typeof this.error;
    },
    response() {
      return this.error.response;
    },
  },
  methods: {
    cleanMessage(message) {
      try {
        JSON.parse(message);
        return 'Server Error, see console for details';
      } catch (e) {
        return message.replace(/[[\]"]/g, '');
      }
    },
    getClasses() {
      const classes = ['alert', 'alert-danger'];

      if (this.classes) {
        classes.push(this.classes);
      }

      return classes.join(' ');
    },
  },
};
</script>

<style lang="scss"></style>
